.copy-clipboard {
    cursor: pointer;
    font-weight: bold;

    &:hover {
        text-decoration: underline;
    }

    &.success {
        cursor: auto;

        &:hover {
            text-decoration: none;
        }
    }
}
