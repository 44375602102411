#hero {
    background: transparent;
    a {
        color: white;
    }
    
    .subtitle {
        max-width: 420px;
    }

    .gif-column {
        display: flex;
        align-items: center;
    }

    &.hero.is-fullheight {
        min-height: 100vh;
        min-height: calc(var(--vh, 1vh) * 100);
    }
}
