@import "../../styles/_colors";

.navbar {
    background: transparent;

    .navbar-brand {
        font-weight: bold;

        svg {
            width: 65px;
            height: auto;
            max-height: none;
            fill: white;
        }

        a.navbar-item {
            &:hover {
                background-color: transparent;
            }
        }
    }

    > .container {
        display: flex;

        .navbar-menu {
            background-color: transparent;
            flex-grow: 1;
            flex-shrink: 0;
            align-items: stretch;
            display: flex;
            margin-right: 0
        }
    }

    

    .navbar-end {
        flex-grow: 1;

        .navbar-item {
            flex-grow: 1;
        }
    }
}

@media screen and (max-width: 1023px) {
    .navbar {
        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            
            .navbar-brand {
                svg {
                    width: 100px;
                }
            }

            .navbar-menu {
                width: 100%;
                padding: 0;
            }

            .navbar-item {
                padding: 0 1.5rem;
            }
        }
    }
}
