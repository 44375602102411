$footer-padding: 3rem 1.5rem;
$radius: 0;
$navbar-height: 4.7rem;

@import "./_colors";
@import "./_spacing_util";
@import "../../node_modules/bulma/bulma";

html {
    background: #27224f;
}

body {
    background: $background-gradient;
    min-height: 100vh;
}

a {
    color: $primary;
    text-decoration: none;

    &:hover {
        color: $primary;
        text-decoration: underline;
    }

    &:visited {
        color: $primary;
    }

    &:focus {
        color: $primary;
    }

    &:link {
        color: $primary;
    }
}

.is-fullheight-with-navbar {
    min-height: calc(100vh - #{$navbar-height});
}

@media screen and (max-width: 1023px) {
    .section {
        padding: 1.5rem;
    }
}
