.personal-card {
    .media {
        .title {
            margin-bottom: 0.4rem;
        }
        .social-links {
            a {
                margin-right: 0.6rem;
            }
        }
    }

    p {
        a {
            text-decoration: underline;
        }
    }
}
