.loading-card {  
    width: 100%;
    float: left;
    border: 1px solid #ddd;
    margin-right: 10px;
    background-color: white;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    min-height: 358px;

    .bars {
      .bar {
        background: #e9ebee;
        margin: 20px 13px;
        height: 19px;
      }

      .bar2 {
        width: 30%;
      }
    }
  }
  
  .loading {
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      display: block;
      background-color: #dddfe2;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      transform: translateX(0);
      animation: 1.5s loading-placeholder ease-in-out infinite;
    }
  }
  
  @keyframes loading-placeholder {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
