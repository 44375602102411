@import "../../styles/colors";

.footer {
    a {
        &:hover {
            color: $blue;
        }
    }
  
    h2 {
        text-align: center;
        font-weight: bold;
    }

    .github-compare-column {
        display: flex;
        align-items: flex-end;

        p {
            color: $primary;
        }
    }

    .copywrite {
        margin: 3.2rem 0 0;
        color: $primary;

        a {
            text-decoration: underline;
        }
    }
}
  