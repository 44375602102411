#introduction {
    .logo {
        max-width: 200px;
        margin-bottom: 1rem;

        &.white {
            fill: white;
        }
    }

    .badge {
        margin-top: 1.5rem;
    }
}
