@import "../../styles/colors";

#select {
    position: relative;
    width: 100%;
        
    .input {
        z-index: 40;
        box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);

        &::placeholder {
            color: $primary;
        }
    }

    &.control.has-icons-left .icon, &.control.has-icons-right .icon {
        z-index: 40;

        .fa-times-circle, .fa-caret-up {
            pointer-events: all;
            cursor: pointer;
        }
    }

    .results {
        position: absolute;
        z-index: 10;
        background: white;
        width: 96%;
        left: 2%;
        border: 0px solid #ccc;
        display: block;
        box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
        color: $primary;
        top: 50%;
        padding-top: 16px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.2s linear;

        &.is-visible {
            visibility: visible;
            opacity: 1;
        }

        .placeholder {
            padding: 0.4rem 0.8rem;
        }
        
        .result-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.4rem 0.8rem;
            cursor: pointer;

            &:hover, &.selected {
                background-color: #4a4a4a;
                color: white;

                .description, .stars {
                    color: #ccc
                }
            }

            &.selected, &.selected:hover {
                background-color: #797979;
            }

            .info {
                display: flex;
                align-items: baseline;
                flex-shrink: 1;
                width: 100%;
                overflow: hidden;

                .name {
                    font-weight: bold;
                    flex-shrink: 0;
                }
                .description {
                    flex: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 0 0.2rem;
                }
            }

            .description, .stars {
                font-size: 0.8rem;
                color: #666;
            }
            

            .stars {
                flex-shrink: 0;
            }
        }
    }
}

@media screen and (max-width: 1023px) {
    #select {
        .results {
            padding-top: 13px;
        }
    }
}
