@import "../../styles/_colors";

.repo-card {
    background: white;
    color: $primary;
    min-height: 358px;
    opacity: 1;
    transition: all 0.6s;

    &.placeholder-card {
        background: transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        border: 3px dashed #fff;
        opacity: 0.2;
        cursor: pointer;

        &:hover {
            opacity: 0.6;
        }

        .add {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 40px;
            width: 40px;
            border: 1px solid #fff;
            line-height: 100%;
            border-radius: 40px;
            background: #fff;
            color: #27224f;
            font-weight: bold;
            font-size: 2rem;
            margin: 12px 0;
        }
    }
    
    &.fade-in {
        opacity: 0;
    }

    &-title {
        display: flex;
        min-height: 60px;
        padding: 6px 10px;
        justify-content: space-between;
        align-items: center;
    }

    &-avatar {
        max-width: 35px;
        display: block;
    }

    &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 10px 6px 10px;

        &-description {
            width: 100%;
            margin-left: 10px;
            font-size: 14px
        }

        &-icon {
            width: 35px;
        }

        &-count {
            width: 200px;
            font-size: 14px
        }

        &--hover {
            transition: all 140ms ease-in;
            background-color: whitesmoke;
            color: #b53d0f;
            cursor: pointer;
        }
    }

    &-footer {
        border-top: 1px solid #ccc;

        p {
            padding: 0.4rem;
            color: red;
            transition: all 0.14s;
            cursor: pointer;
            opacity: 0.2;

            &:hover {
                background-color: red;
                color: white;
                opacity: 1;
            }
        }
    }
      

    hr {
        margin: 0.2rem 0;
        margin-right: 10px;
        margin-left: 10px;
    }
}
