.social-share {
    margin: 1.5rem 0 0;

    p {
        display: inline-block;
        color: white;
        margin: 0.6rem 0 0;
    }

    .is-multiline {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        a {
            margin: 0.5rem 0.5rem 0.5rem 0;
        }

        .copy {
            margin: 0.5em;
        }
    }
}
